<template lang="pug">
  .price-item
    span(:class="feeType")
      | {{ $t(`plan_management.time_slot.${otaName}.${feeType}.${fee}`) }}
    AppNumberInput(
      :value="value"
      :max="999999"
    )
</template>

<script>
  export default {
    components: {
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    props: {
      value: {
        type: Number,
        default: 0
      },
      fee: {
        type: String,
        required: true
      },
      feeType: {
        type: String,
        required: true
      },
      otaName: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="sass" scoped>
  .price-item
    margin-left: 5px

    span
      color: #8893a9
      font-size: 0.6rem

    .hourly
      background-color: #eefbf3

    .daily
      background-color: #fdf5c3

    ::v-deep
      .app-number-input
        .field
          width: 100px
          padding-left: 5px
          text-align: left
</style>
